import React, { Fragment, useState } from 'react';
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { TableCell } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import inRange from 'lodash/inRange';
import styled from '@emotion/styled';
import isEmpty from 'lodash/isEmpty';
import { Sandboxing } from 'models/Sandboxing';
import { SandboxProviders, SandboxInstanceStatus, STATUS_SUMMARY } from 'models/Sandbox';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { useIntl } from 'react-intl';
import { closeModal, openModal } from 'state/modal/actions';
import { useDispatch } from 'react-redux';
import ActionBtn from 'views/components/table/ActionBtn';
import CopyTextButton from 'views/components/CopyText/CopyTextButton';
import { btnMessages } from 'views/components/Button';
import BaseHoverableTableRow from 'views/components/table/HoverableTableRow';
import StatusIcon, { Status } from 'views/pages/SandboxPage/SandboxPanel/StatusIcon';
import { SCORE_MAP } from 'views/pages/SandboxPage/utils';
import { parseSandboxURL } from 'views/url';
import useIsCommunityPlan from 'hooks/useIsCommunityPlan';

interface IMySandboxingTableRow {
  rowData: Sandboxing;
  refetch?: (filters?: { key: string; value: string }[], shouldActiveLoading?: boolean) => void;
  refetchItem: (sha256: string, id: string) => void;
  blur?: boolean;
}

const messages = {
  pending: 'Results available soon. Reload table to see latest status.',
  resandbox: 'Resandbox',
  generateReport: 'Generate Report',
};

const HoverableTableRow = styled(BaseHoverableTableRow)<{ blur?: boolean }>`
  filter: ${({ blur }) => (blur ? 'blur(4px)' : 'none')};
  user-select: ${({ blur }) => (blur ? 'none' : 'auto')};
  cursor: pointer;
`;

const Actions = styled.div`
  white-space: nowrap;
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;

  & #reload-icon[data-active='true'] {
    animation: spin 0.45s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const BoldTableCell = styled(TableCell)`
  font-weight: bold !important;
`;

const Truncated = styled('div')`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: right;
`;

const CellContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 300px;
`;

const CopyButton = styled(CopyTextButton)`
  font-size: 0.4rem;
  margin-left: 0.6rem;
  vertical-align: middle;
`;

const MySandboxingTableRow = ({
  rowData,
  refetch,
  refetchItem,
  blur = false,
}: IMySandboxingTableRow) => {
  const { theme } = makeStyles()();
  const intl = useIntl();
  const [isReloading, setIsReloading] = useState(false);
  const dispatch = useDispatch();
  const isCommunityPlan = useIsCommunityPlan();

  const resandboxing = () =>
    dispatch(
      openModal('SUBMIT_TO_SANDBOX_MODAL', {
        onSubmit: () => {
          dispatch(closeModal());
          refetch?.();
        },
        instanceId: rowData.instanceId,
        defaultType: rowData.type,
        defaultHash: rowData.sha256,
        defaultProvider: rowData.sandboxProvider,
        isResandbox: true,
      })
    );

  const statusMessage = STATUS_SUMMARY[rowData.status as keyof typeof STATUS_SUMMARY] ?? '';

  const selectedProvider =
    SandboxProviders[(rowData?.providerName.toUpperCase() as keyof typeof SandboxProviders) ?? ''];

  const scoreInfo = SCORE_MAP[selectedProvider]?.find(([scoreRange, info]) => {
    if (
      (rowData?.score === 'N/A' || SandboxInstanceStatus.SUCCEEDED !== rowData.status) &&
      scoreRange.includes('N/A')
    ) {
      return true;
    }
    if (rowData?.score !== 'N/A' && !scoreRange.includes('N/A')) {
      const [minN = '', maxN = ''] = scoreRange.split('-');
      const isInRange = inRange(
        Number(rowData?.score),
        0,
        !!maxN ? Number(maxN) + 1 : Number(minN) + 1
      );
      return isInRange;
    }

    return false;
  });

  return (
    <Fragment>
      <HoverableTableRow
        blur={blur}
        renderCells={() => (
          <Fragment>
            <BoldTableCell>{rowData?.sandboxOn}</BoldTableCell>
            <TableCell>
              <Tooltip title={rowData?.target ?? undefined} placement='top'>
                <CellContainer>
                  <Truncated>{rowData?.target ?? '-'}</Truncated>
                  {!!rowData?.target && <CopyButton text={rowData?.target} />}
                </CellContainer>
              </Tooltip>
            </TableCell>
            <TableCell>{rowData?.type}</TableCell>
            <TableCell>
              <Tooltip title={rowData?.sha256 ?? ''} placement='top'>
                <span>
                  {`${!!rowData?.sha256 ? rowData?.sha256?.substring(0, 15) : 'Processing'}…`}{' '}
                  {!!rowData?.sha256 && <CopyButton text={rowData?.sha256} />}
                </span>
              </Tooltip>
            </TableCell>
            <TableCell>{rowData?.sandboxProvider}</TableCell>
            <TableCell>
              <span style={{ color: theme === 'dark' ? scoreInfo?.[3] : scoreInfo?.[2] }}>
                {SandboxInstanceStatus.SUCCEEDED !== rowData.status
                  ? 'N/A'
                  : rowData?.score === 'N/A'
                  ? rowData?.score
                  : `${rowData?.score} / 10`}
              </span>
            </TableCell>
            <TableCell>
              <Tooltip title={statusMessage} placement='top'>
                <span>
                  <StatusIcon status={rowData.status as Status} />
                </span>
              </Tooltip>
            </TableCell>
            <TableCell>{rowData?.triggeredBy}</TableCell>
            <TableCell align='right'>
              <Actions>
                {[
                  SandboxInstanceStatus.DELAYED,
                  SandboxInstanceStatus.STARTED,
                  SandboxInstanceStatus.PENDING,
                  SandboxInstanceStatus.COLLECTING_DATA,
                ].includes(rowData.status as SandboxInstanceStatus) ? (
                  <ActionBtn
                    id='reload-icon'
                    name='rescan'
                    title='Reload'
                    aria-label='Reload'
                    data-active={isReloading}
                    disabled={isEmpty(rowData?.sha256)}
                    onClick={async () => {
                      if (!!rowData?.sha256) {
                        setIsReloading(true);
                        await refetchItem?.(rowData.sha256, rowData.id);
                        setIsReloading(false);
                      }
                    }}
                  />
                ) : null}
                {![
                  SandboxInstanceStatus.FAILED,
                  SandboxInstanceStatus.FAILED_REIMBURSED,
                  SandboxInstanceStatus.TIMEDOUT_REIMBURSED,
                ].includes(rowData.status as SandboxInstanceStatus) ? (
                  <Tooltip
                    title={
                      [SandboxInstanceStatus.DELAYED, SandboxInstanceStatus.STARTED].includes(
                        rowData.status as SandboxInstanceStatus
                      )
                        ? messages.pending
                        : ''
                    }
                    placement='top'
                  >
                    <div>
                      <ActionBtn
                        isShown={rowData.status === SandboxInstanceStatus.SUCCEEDED}
                        name='generate-report'
                        title={
                          isCommunityPlan
                            ? 'Generate Report is not available for Community Plan users'
                            : messages.generateReport
                        }
                        aria-label={messages.generateReport}
                        onClick={() => {
                          dispatch(
                            openModal('GENERATE_REPORT', {
                              type: 'sandbox',
                              id: rowData.id,
                            })
                          );
                        }}
                      />
                      <ActionBtn
                        name='open-view'
                        style={{ marginLeft: 8 }}
                        disabled={
                          blur ||
                          rowData.status !== SandboxInstanceStatus.SUCCEEDED ||
                          isEmpty(rowData?.sha256)
                        }
                        title={intl.formatMessage(btnMessages.info)}
                        aria-label={intl.formatMessage(btnMessages.info)}
                        onClick={() =>
                          window.open(
                            parseSandboxURL(rowData.sha256, {
                              sandboxType: rowData.type,
                              sandboxId: rowData.id,
                            }),
                            '_blank'
                          )
                        }
                      />
                    </div>
                  </Tooltip>
                ) : (
                  <div>
                    <ActionBtn
                      name='sandbox'
                      style={{ marginLeft: 8 }}
                      title={messages.resandbox}
                      aria-label={messages.resandbox}
                      onClick={resandboxing}
                    />
                  </div>
                )}
              </Actions>
            </TableCell>
          </Fragment>
        )}
      />
    </Fragment>
  );
};
export default MySandboxingTableRow;
