import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/root';
import { AccountFeature } from 'models/Account';

export default function useHasFeature(feature?: string) {
  const [loading, setLoading] = useState(true);
  const {
    features,
    hasFeature,
    remainingUses = 0,
  } = useSelector<
    RootState,
    { hasFeature: boolean; remainingUses?: boolean; features: AccountFeature[] }
  >(({ user, team, account }) => {
    // Get features for either team or user depending on context
    let features: AccountFeature[] = [];
    if (account.context?.team && team.account) {
      features = team.account.features;
    } else if (user.account?.features) {
      features = user.account.features;
    }

    const filteredFeature = features.find((f) => f.tag === feature);

    return {
      features,
      hasFeature: !!filteredFeature,
      remainingUses:
        filteredFeature && (filteredFeature.value || filteredFeature?.remainingUses > 0),
    };
  });

  const checkFeature = useCallback(
    (feature: string | string[]) => {
      if (Array.isArray(feature)) {
        return feature.some((ft) => {
          const featureData = features.find((f) => f.tag === ft);
          return featureData?.value || (featureData?.remainingUses || 0) > 0;
        });
      }
      const featureData = features.find((f) => f.tag === feature);
      return featureData?.value || (featureData?.remainingUses || 0) > 0;
    },
    [features]
  );

  // Wait for user's features to load
  setTimeout(() => {
    if (loading) setLoading(false);
  }, 1500);
  if (hasFeature && loading) setLoading(false);
  return { hasFeature, remainingUses, hasPermission: hasFeature, loading, checkFeature };
}
