import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { FormattedMessage } from 'react-intl';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { getPlanSlugByName, accountPlans } from 'state/utils/account';
import Card from 'views/components/layout/Card';
import styles from 'views/styles';
import { differenceInDays, addYears } from 'date-fns';
import { toDate } from 'date-fns-tz';
import { DATE_TIME_FORMAT, FULL_DATE_FORMAT } from 'utils/constants';
import { format as formatDate } from 'date-fns';
import { Tooltip } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { AccountLevelProgress } from './AccountLevelProgress';

export interface IAccountPlan {
  className?: string;
  accountPlanName: string;
  usagePeriodStart: string;
  usagePeriodEnd: string;
  subscriptionStart: string | null;
  subscriptionEnd: string | null;
  onClick: () => void;
}

export const AccountPlan = ({
  className,
  accountPlanName,
  usagePeriodStart,
  usagePeriodEnd,
  subscriptionStart,
  subscriptionEnd,
  onClick,
}: IAccountPlan) => {
  const subscriptionStartDate = subscriptionStart ? toDate(subscriptionStart) : null;
  const subscriptionEndDate = subscriptionEnd ? toDate(subscriptionEnd) : null;
  const startTime = toDate(usagePeriodStart);
  const endTime = toDate(usagePeriodEnd);
  const now = new Date();
  const daysRemaining = differenceInDays(endTime, now) || 0;
  const totalDays = differenceInDays(endTime, startTime);
  const percentage = Math.max(0, 1 - daysRemaining / totalDays);

  const { classes } = useStyles();

  return (
    <Card className={className} css={classes.root}>
      <div css={classes.accountLevel}>
        <span css={classes.planName} data-cy='trialPlanName'>
          {accountPlans.includes(getPlanSlugByName(accountPlanName) as any)
            ? accountPlanName
            : 'Custom'}{' '}
          <FormattedMessage id='accountPlan.name' defaultMessage='Plan' />
        </span>
        {subscriptionStartDate && (
          <React.Fragment>
            <p className='h-mt-xxs h-mb-xxs'>
              <span css={classes.usageHeading}>
                <FormattedMessage id='accountPlan.subscription' defaultMessage='Subscription:' />
              </span>
              <span css={classes.bold}>
                {formatDate(subscriptionStartDate, FULL_DATE_FORMAT)}
                {' - '}
                {formatDate(
                  subscriptionEndDate ? subscriptionEndDate : addYears(subscriptionStartDate, 1),
                  FULL_DATE_FORMAT
                )}
              </span>
            </p>
            <Divider />
          </React.Fragment>
        )}
        <div className='h-mt-xxs h-mb-xxs'>
          <div css={classes.bold}>
            <span css={classes.usageHeading}>
              <FormattedMessage
                id='accountPlan.monthlyQuotaReset'
                defaultMessage='Monthly Quota Reset:'
              />
            </span>
            <span>{formatDate(endTime, DATE_TIME_FORMAT)} UTC</span>
          </div>
        </div>
        <Tooltip
          interactive
          title={
            <div>
              <span>
                {daysRemaining}{' '}
                {daysRemaining === 1 ? (
                  <FormattedMessage id='accountPlan.period.day' defaultMessage='day remaining' />
                ) : (
                  <FormattedMessage id='accountPlan.period.days' defaultMessage='days remaining' />
                )}
              </span>
            </div>
          }
          placement='top'>
          <div>
            <AccountLevelProgress percentage={percentage} />
          </div>
        </Tooltip>
      </div>
    </Card>
  );
};

const useStyles = makeStyles({
  base: {
    root: {
      overflow: 'hidden',
    },
    accountLevel: {
      padding: styles.spacing.sm,
    },
    planName: {
      fontSize: styles.font.size.h6,
      fontWeight: styles.font.weight.bold,
    },
    usageHeading: {
      whiteSpace: 'nowrap',
      color: styles.color.xLightGrey,
      fontSize: styles.font.size.p1,
      fontWeight: styles.font.weight.medium,
      marginRight: '0.5rem',
    },
    usageText: {
      display: 'inline-block',
      whiteSpace: 'nowrap',
      fontSize: styles.font.size.p,
      fontWeight: styles.font.weight.bold,
    },
    btn: {
      cursor: 'pointer',
      fontSize: styles.font.size.p1,
      fontWeight: styles.font.weight.bold,
    },
    bold: {
      fontSize: '1.3rem',
      fontWeight: styles.font.weight.bold,
    },
  },
  light: {
    root: {
      backgroundColor: styles.color.white,
      color: styles.color.black,
    },
  },
  dark: {
    root: {
      backgroundColor: styles.color.xxDarkPurple,
      color: styles.color.white,
    },
  },
});
