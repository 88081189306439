import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from 'views/components/Icon';
import styles from 'views/styles';
import { transformFeatureUsage } from 'state/utils/usage';
import { Account, AccountUsage, AccountFeatureTag } from 'models/Account';
import { Team } from 'models/Team';
import { AccountPlan } from 'models/Account';
import { RootState } from 'state/root';
import UsageTableV2, { featuresTexts } from 'views/components/table/UsageTableV2/UsageTable';
import PanelLoader from 'views/components/Loader/PanelLoader';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { AccountFeature } from 'models/Account';
import formatBytes from 'utils/formatBytes/formatBytes';
import tenant from 'tenants';

interface StateProps {
  plans: AccountPlan[];
  team: Team;
  featuresUsage: AccountUsage[];
  account?: Account;
}

export const numberFeatures = [
  'apiRequestLimit',
  'monthlyApiLimit',
  'scans',
  'hashSearches',
  'metadataSearches',
  'downloads',
  'iocUser',
  'sandboxRequest',
  'sandboxSearch',
  'liveHunts',
  'huntingYaraRules',
  'historicalHunts',
  'tagsView',
];

export const booleanFeatures = [
  'createTeams',
  'taxii',
  // 'hasNotifications', // Not shown for now
  'integrationOk',
  // 'taxiiTrustgroupAccess', // Not shown for now
  'privateComm',
  // 'hasStreamAccess', // Not shown for now
  // 'taxiiRansomwareAccess', // Not shown for now
  'hasSupport',
  'maxArtifactSize',
];

export const TeamUsageTab = () => {
  const { theme } = useStyles();

  const { featuresUsage } = useSelector<RootState, StateProps>(
    ({ account: { plans }, team }) => {
      const features: AccountFeature[] = team?.account?.features ? team.account.features : [];

      return {
        plans: plans,
        team: team.single!,
        featuresUsage: transformFeatureUsage(features),
      };
    }
  );

  const usageByFeature = [...numberFeatures, ...booleanFeatures]
    .filter(
      (feature) =>
        !tenant.disabledUsageFeatures?.includes(feature as (typeof tenant.disabledUsageFeatures)[0])
    )
    .map((feature) => {
      const featureUsage = featuresUsage.find((u) => u.feature === feature);

      if (featureUsage) {
        return { ...featureUsage, isIncludedOnPlan: true };
      }
      return {
        feature: feature as AccountFeatureTag,
        baseUses: 0,
        baseQuota: 0,
        numUses: 0,
        remainingUses: 0,
        totalAvail: 0,
        overage: 0,
        value: false,
        isIncludedOnPlan: false,
      } as any;
    });

  if (usageByFeature?.length <= 0) return <PanelLoader />;

  return (
    <Container>
      <Header>Quotas</Header>
      <UsageTableV2
        isDark={theme === 'dark'}
        features={usageByFeature
          .filter((usage) => numberFeatures.includes(usage.feature))}
      />
      <hr
        className='separator'
        style={{
          margin: `2rem 0.5rem 2rem 30px`,
          border: '0.05rem solid #ccc',
        }}
      />
      <Header>Features</Header>
      <Features>
        {usageByFeature
          .filter((usage) => booleanFeatures.includes(usage.feature))
          .map((item) => (
            <FeatureIcon isDark={theme === 'dark'} active={item.isIncludedOnPlan}>
              {(featuresTexts as any)[item.feature].label}
              {item.feature === 'maxArtifactSize' && item.baseUses
                ? ` (${formatBytes(item.baseUses)})`
                : ''}
              <Tooltip
                placement='top'
                title={(featuresTexts as any)[item.feature].info}
              >
                <div>
                  <Icon className='helpIcon' name='info' />
                </div>
              </Tooltip>
            </FeatureIcon>
          ))}
      </Features>
    </Container>
  );
};

const useStyles = makeStyles({
  base: {},
  light: {},
  dark: {},
});

const FeatureIcon = styled.div<{ active: boolean; isDark: boolean }>`
  display: flex;
  color: ${(props) =>
    props.active
      ? props.isDark
        ? styles.color.white
        : styles.color.black
      : styles.color.xLightGrey};

  & .helpIcon {
    cursor: help;
    font-size: 0.8rem;
    padding-left: 1rem;
    position: relative;
    color: styles.color.xLightGrey;
  }
`;

const Header = styled.h1`
  font-size: 2.8rem;
  font-weight: ${styles.font.weight.bold};
  margin-left: 3rem;
`;

const Container = styled.div`
  padding: 1.7rem 0;
  padding-left: 0rem;
  padding-right: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 1.7rem;
`;

const Features = styled.div`
  display: grid;
  grid-template-columns: auto auto 1fr;
  grid-column-gap: 4rem;
  grid-row-gap: 1rem;
  width: 100%;
  margin-left: 3rem;
`;
